exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-bento-js": () => import("./../../../src/pages/bento.js" /* webpackChunkName: "component---src-pages-bento-js" */),
  "component---src-pages-dessert-js": () => import("./../../../src/pages/dessert.js" /* webpackChunkName: "component---src-pages-dessert-js" */),
  "component---src-pages-greeting-js": () => import("./../../../src/pages/greeting.js" /* webpackChunkName: "component---src-pages-greeting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-article-1-article-detail-js": () => import("./../../../src/templates/Article1/article-detail.js" /* webpackChunkName: "component---src-templates-article-1-article-detail-js" */),
  "component---src-templates-article-1-article-list-js": () => import("./../../../src/templates/Article1/article-list.js" /* webpackChunkName: "component---src-templates-article-1-article-list-js" */)
}

